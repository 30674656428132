import React from 'react'
import Logo from '../assets/logo-white.png'
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header = () => {

  window.addEventListener("scroll", function(){
    var navbar = this.document.querySelector(".navbar");
    navbar.classList.toggle("sticky", this.window.scrollY > 0); 
  })

  return (
  <div className="navbar bg-base-100">
    <div className="navbar-start">
      <div className="dropdown">
        <label tabIndex={0} className="btn btn-ghost lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
        </label>
        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-content rounded-box w-52">
          <li><a href="#section1">Beranda</a></li>
          <li>
            <a href="#section3">Unit</a>
            {/* <ul className="p-2">
              <li><a>Submenu 1</a></li>
              <li><a>Submenu 2</a></li>
            </ul> */}
          </li>
          <li><a href="#section4">Site Plan</a></li>
          <li><a href="#section5">Fasilitas</a></li>
        </ul>
      </div>
      <a className="btn btn-ghost normal-case text-xl" href="#section1">
          <img className="logo" src={Logo} alt="" />
      </a>
    </div>
    <div className="navbar-end hidden lg:flex">
      <ul className="menu menu-horizontal px-1">
        <li><a href="#section1">Beranda</a></li>
        <li><a href="#section3">Unit</a></li>
        <li><a href="#section4">Site Plan</a></li>
        <li><a href="#section5">Fasilitas</a></li>
        {/* <li><a href='#section5'>Hubungi Kami</a></li> */}
        {/* <li tabIndex={0}>
          <details>
            <summary>Parent</summary>
            <ul className="p-2">
              <li><a>Submenu 1</a></li>
              <li><a>Submenu 2</a></li>
            </ul>
          </details>
        </li>
        <li><a>Item 3</a></li> */}
      </ul>
    </div>
    {/* <div className="navbar-end">
      <a className="btn btnKontak"><FontAwesomeIcon icon={faPhone} />Hubungi Kami</a>
    </div> */}
  </div>
  )
}

export default Header