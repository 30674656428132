import React from 'react'

const Footer = () => {
  return <>
  
    <footer className="footer footer1 p-10 bg-base-200 text-base-content">
        <nav>
            {/* <img src={Logo} alt="" /> */}
            <header className="footer-title" data-aos="fade-up" data-aos-anchor-placement="center-bottom">Marketing Office :</header> 
            <a href="https://www.google.com/maps/place/Serambi+Temu/@-6.3013238,106.7592378,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69efdfdb13798d:0xd9a4398be06dcccc!8m2!3d-6.3013291!4d106.7618127!16s%2Fg%2F11hdvmllqr?entry=ttu" className="link link-hover" data-aos="fade-up" data-aos-anchor-placement="center-bottom" target="_blank">Jl. Ir H. Juanda No.26, Situgintung, Cireundeu, Kec. Ciputat Tim., Kota Tangerang Selatan, Banten 15419</a>
            <a href="https://api.whatsapp.com/send/?phone=%2B6285180759648&text&type=phone_number&app_absent=0" className="link link-hover" data-aos="fade-up" data-aos-anchor-placement="center-bottom" target="_blank">Tel : +62 851-8075-9648</a>
            <div className="gmaps">
                <div id="my-map-canvas" style={{
                    height:"100%",
                    width:"100%",
                    maxWidth:"100%",
                    overflow:"hidden"}}>
                    <iframe style={{
                        height:"100%",
                        width:"100%",
                        border:"0"}} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Gg.+Bojong+No.62,+Rengas,+Kec.+Ciputat+Tim.,+Kota+Tangerang+Selatan,+Banten+15412,+Indonesia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
                    </iframe>
                </div>
            </div>
        </nav> 
        <nav className="grid-flow-col gap-3">
            <header className="footer-title" data-aos="fade-up" data-aos-anchor-placement="center-bottom">Follow Us On:</header> 
            <a className="link link-hover" data-aos="fade-up" data-aos-anchor-placement="center-bottom"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" className="fill-current"><path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/></svg></a> 
            <a className="link link-hover" data-aos="fade-up" data-aos-anchor-placement="center-bottom"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
        </nav>
    </footer>
    <footer className="footer footer2 items-center p-4 bg-base text-base">
        <aside className="items-center grid-flow-col" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">Ruma Kotak Bintaro © 2023 - Hak Cipta dilindungi.</p>
        </aside> 
    </footer>
  </>
}

export default Footer