import { useEffect } from 'react';
import './App.css';
import Banner from './component/Banner'
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  
  useEffect(() =>{
    AOS.init({
      duration : 900
    })
  }, [])

  return (
    <div className="App">
      <Banner />
    </div>
  );
}

export default App;
