import React from 'react'
import Header from './Header';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

// Import Image Banner
import Carousel1 from '../assets/Home.png'

// Import Brochure
import Ebrochure from "../assets/download/Ruma_Kotak_Bintaro.pdf"

import Tab from './Tab';

function Banner() {

  return <>
    <Header/>
    <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
            delay: 5000,
            disableOnInteraction: false,
        }}
        modules={[Autoplay,Pagination]}
        className="mySwiper banner"
        id="section1"
      >
        <SwiperSlide 
        style={{ 
            backgroundImage:`url(${Carousel1})`, 
            height : "100vh", backgroundSize : "Cover", 
            backgroundPosition : "Center",
        }}>
            <div className="max-w-md cardBanner">
                <h1 className="font-bold">Living Comfortably</h1>
                <p className="py-6">whitin One Home</p>
                <a 
                href={Ebrochure}
                target="_blank"
                rel="noreferrer"
                className="btn btn-download">
                    Download E-Brochure
                </a>
            </div>
        </SwiperSlide>
        {/* <SwiperSlide 
        style={{ 
            backgroundImage:`url(${Carousel2})`, 
            height : "100vh", backgroundSize : "Cover", 
            backgroundPosition : "Center",
        }}>
            <div className="max-w-md cardBanner">
                <h1 className="text-5xl font-bold">Living Comfortably</h1>
                <p className="py-6 text-3xl">whitin One Home</p>
                <button className="btn btn-info">Download E-Brochure</button>
            </div>
        </SwiperSlide>
        <SwiperSlide
        style={{ 
            backgroundImage:`url(${Carousel3})`, 
            height : "100vh", backgroundSize : "Cover", 
            backgroundPosition : "Center",
        }}>
            <div className="max-w-md cardBanner">
                <h1 className="text-5xl font-bold">Living Comfortably</h1>
                <p className="py-6 text-3xl">whitin One Home</p>
                <button className="btn btn-info">Download E-Brochure</button>
            </div>
        </SwiperSlide> */}
    </Swiper>
    <Tab />
    </>
}

export default Banner;