import React from 'react'

import Img1 from "../assets/siteplan1.png"

const Siteplan = () => {
  return <>
    <div className="containerSiteplan" id="section4" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
        <div className="siteplan">
            <div className="siteplanImg">
                <img src={Img1} alt="" id="section4" data-aos="fade-up" data-aos-anchor-placement="center-bottom"/>
            </div>
        </div>
    </div>
    </>
}

export default Siteplan