import React from 'react'
import Siteplan from './Siteplan'

// Icon
import { faClock, faBowlRice, faHandshake, faBellConcierge  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Footer from './Footer';

// Image
import slider1 from "../assets/fasilitas/1.png"
import slider2 from "../assets/fasilitas/2.png"
import slider3 from "../assets/fasilitas/3.png"
import slider4 from "../assets/fasilitas/4.png"
import slider5 from "../assets/fasilitas/5.png"
import slider6 from "../assets/fasilitas/6.png"
import Maps from "../assets/Maps.png"
import KPR from "../assets/KPR.png"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Facilities = () => {
  return <>
        
        <Siteplan />

        <div className="containerFacilities" id="section5">
            <div className="contentFacilities">
                <h1 data-aos="fade-up" data-aos-anchor-placement="center-bottom">Surrounding Facilities</h1>
                <div className="textFacilities">
                    <div className="facilities">
                            <h3 data-aos="fade-up" data-aos-anchor-placement="center-bottom">Healt Facilities</h3>
                            <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">6.1 km RS Premier Bintaro</li>
                            <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">14 km RS Eka Hospital</li>
                            <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">3.7 km RS DR. Suyoto</li>
                    </div>
                    <div className="facilities">
                        <h3 data-aos="fade-up" data-aos-anchor-placement="center-bottom" >Leisure Zones</h3>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >6.7 km Pondok Indah Mall</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >6.1 km Bintaro Exchange</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >1.9 km Bintaro Plaza</li>
                    </div>
                </div>
                <div className="textFacilities">
                    <div className="facilities">
                        <h3 data-aos="fade-up" data-aos-anchor-placement="center-bottom" >Transportation</h3>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >750 m Pondok Ranji Station</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >1.7 km Tol Bintaro Sektor 2</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >7.8 km Tol Tanah Kusir</li>
                    </div>
                    <div className="facilities">
                        <h3 data-aos="fade-up" data-aos-anchor-placement="center-bottom" >Education Facilities</h3>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >4.4 km Al-Azhar Bintaro</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >3.9 km Millennia Word School</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom" >4.8 Jakarta Emerald School</li>
                    </div>
                </div>
                <div className="textFacilities">
                    <div className="facilities">
                        <h3 data-aos="fade-up" data-aos-anchor-placement="center-bottom">Grocery Areas</h3>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">9.1 km Giant Kreo</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">700 m Sektor 2 Grocery</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">5.0 km Carrefour Lebak Bulus</li>
                    </div>
                    <div className="facilities">
                        <h3 data-aos="fade-up" data-aos-anchor-placement="center-bottom">Mosque</h3>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">1.3 km Jami Mosque Bintaro Jaya</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">10 km Masjid Jami Assadah</li>
                        <li data-aos="fade-up" data-aos-anchor-placement="center-bottom">1.8 km As Salam Mosque</li>
                    </div>
                </div>
            </div>
            <div className="sliderFacilities">
                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    rewind={true}
                    autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                    data-aos="fade-up" data-aos-anchor-placement="center-bottom"
                >
                    <SwiperSlide>
                        <img src={slider1} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slider2} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slider3} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slider4} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slider5} alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={slider6} alt="" />
                    </SwiperSlide>
                </Swiper> 
            </div>
        </div>

        <div className="containerMaps" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            <img src={Maps} alt="" />
        </div>

        <div className="containerKPR" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
            <img src={KPR} alt="" />
        </div>

        <Footer />

    </>
}

export default Facilities