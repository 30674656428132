import React from 'react'
import { useState } from 'react';

// Import Comoponent
import Facilities from './Facilities';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

// Import fslightbox
import FsLightbox from "fslightbox-react";

import Tipe66 from "../assets/DetailTipe66.png"
import Tipe85 from "../assets/DetailTipe85.png"


const Tab = () => {
  
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    const [toggler, setToggler] = useState(false);
  
    return <>

        {/* TAB VIEW */}
        <div id="section3">
            <div className={toggleState === 1 ? "container content active-content" : "content"}>
                <div className="containerTab">
                    <h1 data-aos="fade-up" data-aos-anchor-placement="center-bottom">Tipe Unit</h1>
                    <div className="tab-lg mt-5">
                        <a className={toggleState === 1 ? "tab tab-lifted tab-active" : "tab tab-lifted"} onClick={() => toggleTab(1)}>Tipe 66</a> 
                        <a className={toggleState === 2 ? "tab tab-lifted tab-active" : "tab tab-lifted"} onClick={() => toggleTab(2)}>Tipe 85</a>
                        <div className="containerDetail">
                            <button className="btn btnDetail" onClick={() => setToggler(!toggler)}>
                                Detail Unit
                            </button>
                            <FsLightbox
                                toggler={toggler}
                                sources={[Tipe66]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={toggleState === 2 ? "container1 content active-content" : "content"}>
                <div className="containerTab">
                    <h1 data-aos="fade-up" data-aos-anchor-placement="center-bottom">Tipe Unit</h1>
                    <div className="tab-lg mt-5">
                        <a className={toggleState === 1 ? "tab tab-lifted tab-active" : "tab tab-lifted"} onClick={() => toggleTab(1)}>Tipe 66</a> 
                        <a className={toggleState === 2 ? "tab tab-lifted tab-active" : "tab tab-lifted"} onClick={() => toggleTab(2)}>Tipe 85</a>
                        <div className="containerDetail">
                            <button className="btn btnDetail" onClick={() => setToggler(!toggler)}>
                                Detail Unit
                            </button>
                            <FsLightbox
                                toggler={toggler}
                                sources={[Tipe85]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Facilities />
  </>
}

export default Tab